<template>
    <div class="container">
        <div class="row align-items-center min-vh-100 w-100">
            <div class="col-12">
                <div class="row justify-content-center">
                    <div class="col-md-6 col-xl-4">
                        <h1 class="mb-5 pt-3 text-center"><strong>ProTrader</strong></h1>
                        <form @submit.prevent="sendResetLink"
                              novalidate
                              class="card-body pb-3">
                            <h4 class="card-title">Reset Password</h4>
                            <AppInput v-model="email"
                                      label="Email"
                                      placeholder="Email"
                                      field="email"
                                      :errors="errors"
                                      size="lg"
                                      class="mb-3"/>
                            <div class="d-grid">
                                <button type="submit"
                                        class="btn btn-lg btn-primary btn-block"
                                        :disabled="loading">
                                    Send reset link
                                </button>
                            </div>
                        </form>
                        <router-link class="btn btn-link btn-block"
                                     :to="{name: 'login'}">
                            Back to login form
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Errors from '@/utils/errors';
import Password from '@/services/password.service';
import NotificationService from '@/services/notification.service';
import AppInput from '@/components/form/AppInput';

export default {
  components: {
    AppInput,
  },
  data() {
    return {
      email: '',
      loading: false,
      errors: new Errors(),
    };
  },
  methods: {
    sendResetLink() {
      this.errors.clear();
      this.loading = true;

      Password.sendResetLink(this.email)
        .then((res) => {
          NotificationService.success(res.message);
          this.email = null;
          return res;
        })
        .catch((err) => this.errors.set(err.response.data.errors))
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
