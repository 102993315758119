import http from './http.service';

class Password {
  reset(email, token, password, password_confirmation) {
    return http.post('password/reset', {
      email,
      token,
      password,
      password_confirmation,
    })
      .then((res) => res.data);
  }

  sendResetLink(email) {
    return http.post('password/email', {
      email,
    })
      .then((res) => res.data);
  }
}

export default new Password();
